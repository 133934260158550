<script>
import { CalendarIcon, ChevronDown, CloseCircle, DocumentIcon, IconNewTab, StoreLarge } from "@/icons";
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapGetters, mapState } from 'vuex';
import DateRangeFilter from "@/views/payouts/components/DateRangeFilter.vue";
import moment from "moment";
import PageHeader from "@/views/payouts/components/PageHeader.vue";
import payoutsMixin from "@/views/payouts/mixin/payoutsMixin";
import SelectFilter from "@/views/payouts/components/SelectFilter.vue";
import ViewPayoutDialog from "@/views/payouts/components/ViewPayoutDialog.vue";
import SourceTabs from '@/views/payouts/components/SourceTabs.vue'
var Nanobar = require('../../../../../node_modules/nanobar/nanobar');
let nanobar = new Nanobar();
import Pagination from '@/components/UI/Pagination.vue'

export default {
  name: "SourceCompletePayouts",

  mixins: [payoutsMixin],

  data() {
    return {
      filteredStoreOptions: [],
      isOrderFilterVisible: false,
      isSearchingStore: false,
      isStoreFilterVisible: false,
      storeSearchTerm: "",
    }
  },

  components: {
    CalendarIcon,
    ChevronDown,
    CloseCircle,
    DateRangeFilter,
    DocumentIcon,
    IconNewTab,
    PageHeader,
    SelectFilter,
    StoreLarge,
    ViewPayoutDialog,
    SourceTabs,
    Pagination,
  },

  computed: {
    ...mapState("shop", ["connectedShops"]),
    ...mapGetters("shop", ["currentShop"]),

    storeFilterOptions() {
      return this.connectedShops.map(shop => {
        return {
          key: "target_store",
          text: shop.store_domain,
          value: shop.id,
          commission: shop.store_commission
        }
      });
    },
  },

  async created() {
    try {
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });

      this.filteredStoreOptions = [...this.filteredStoreOptions, ...this.storeFilterOptions];
      this.filteredStoreOptions = this.arrayToObject(this.filteredStoreOptions, "value");
      this.setDefaultStartAndEndDate();
      this.$store.dispatch("payouts/fetchDashboardStats", this.currentShop.id);
      await this.fetchSourceCompletePayouts();
    } catch(error) {
      console.log(error);
    }
  },

  methods: {
    setPayoutFilters() {
      let dateRange = "";
      this.filters.forEach(filter => {
        if(Object.prototype.hasOwnProperty.call(filter, "date_range")) {
          dateRange = filter.date_range;
        }
      });

      this.filters = [];
      const { orders_status, target_store } = this.sourceCompletePayoutsFilters;

      if(target_store !== "All Stores") {
        this.filters.push({ origin_store: target_store.value });
      }

      this.filters.push({ status: orders_status });

      if(this.searchTerm !== "") {
        this.filters.push({ payout_id: this.searchTerm });
      }

      const { endDate, startDate } = this.dateRange;
      let formattedStartDate = moment(startDate || dateRange.split("to")[0].trim()).format("YYYY-MM-DD");
      let formattedEndDate = moment(endDate || dateRange.split("to")[1].trim()).format("YYYY-MM-DD");
      this.filters.push({ date_range: `${formattedStartDate} to ${formattedEndDate}` });
    },

    async fetchSourceCompletePayouts(page) {
      nanobar.go(this.randomNumber(20, 90));
      this.setPayoutFilters();
      await this.$store.dispatch("payouts/fetchSourceCompletePayouts", {
        current_store_id: this.currentShop.id,
        filters: this.filters,
        limiter: this.limiter,
        page
      });
      nanobar.go(100);
    },

    async onDateRangeSelectedHandler(value) {
      const { label } = value;
      this.sourceCompletePayoutsFilters.date_range = label;
      this.dateRange.startDate = value.startDate;
      this.dateRange.endDate = value.endDate;
      await this.fetchSourceCompletePayouts(1);
    },

    async filterByLatestPayoutsHandler() {
      this.sourceCompletePayoutsFilters.date_range = "Last 30 days";
      this.setDefaultStartAndEndDate();
      await this.fetchSourceCompletePayouts(1);
    },

    swapItems() {
      this.isSearchingStore = true;
      document.querySelector(".search-input").focus();
    },

    onClickOutsideStoreFilter() {
      this.isStoreFilterVisible = false;
    },

    onClickOutsideOrderFilter() {
      this.isOrderFilterVisible = false;
    },

    onSearchStoreHandler(event) {
      this.filteredStoreOptions = this.arrayToObject(this.storeFilterOptions.filter(store => store.text.includes(event.target.value)), "value");
    },

    async applyStoreFilter(filter) {
      this.isStoreFilterVisible = false;
      this.sourceCompletePayoutsFilters.target_store = filter;
      this.selectedRecordStore = filter;
      await this.fetchSourceCompletePayouts(1);
    },

    closeFilters() {
      this.isOrderFilterVisible = false;
      this.isStoreFilterVisible = false;
    },

    async applyFilter({ key, value }) {
      this.sourceCompletePayoutsFilters[key] = value;
      this.closeFilters();
      await this.fetchSourceCompletePayouts(1);
    },

    async filterByPayoutsToConfirmHandler() {
      const payoutsToConfirm = { key: "orders_status", value: "paid" };
      await this.applyFilter(payoutsToConfirm);
      this.sourceCompletePayoutsCurrentPage = 1;
    },

    async searchOrders() {
      await this.fetchSourceCompletePayouts();
      this.sourceCompletePayoutsCurrentPage = 1;
    },

    async onClearFiltersHandler() {
      this.sourceCompletePayoutsFilters.orders_status = "";
      this.searchTerm = "";
      this.setDefaultStartAndEndDate();
      this.sourceCompletePayoutsFilters.date_range = "Last 30 days";
      this.sourceCompletePayoutsFilters.target_store = "All Stores";
      this.sourceCompletePayoutsFilters.orders_status = "payment_confirmed"
      this.selectedRecordStore = { text: "All Stores" };
      await this.fetchSourceCompletePayouts(1);
    },

    async confirmPayoutHandler(payout) {
      await this.$store.dispatch("payouts/confirmPayout", {
        current_store_id: this.currentShop.id,
        payout_id: payout.payout_id
      });

      await this.fetchSourceCompletePayouts();
      await this.$store.dispatch(`shop/loadCurrentShop`);
    },

    shouldPayoutConfirmVisible(payout) {
      return payout.status !== 'cancelled' && payout.status !== 'payment_confirmed'
    },

    updateCurrentPageHandler(page) {
      this.sourceCompletePayoutsCurrentPage = page
      this.fetchSourceCompletePayouts(this.sourceCompletePayoutsCurrentPage);
    }
  }
}
</script>

<template>
  <section class="page payouts-page">
    <PageHeader pageDescription="Manage Payouts for fulfilled orders">
      <template #title>Manage payouts</template>
    </PageHeader>

    <SourceTabs />

    <!-- Filters -->
    <section class="filters-listing">
      <ul class="d-flex pa-0 justify-start">
        <li class="filters-listing__filter" data-filter="stores" @click.capture="isStoreFilterVisible = true" v-click-outside="onClickOutsideStoreFilter">
          <SelectFilter>
            <template #iconBefore>
              <StoreLarge />
            </template>
            <template #label>
              <div class="label" @click="swapItems">
                <input type="text" v-model="storeSearchTerm" v-show="isStoreFilterVisible" class="search-input" @keyup="onSearchStoreHandler">
                <span v-show="!isStoreFilterVisible">{{ sourceCompletePayoutsFilters.target_store.text || sourceCompletePayoutsFilters.target_store }}</span>
              </div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <template #options>
              <transition name="slide-x-reverse-transition">
                <ul v-if="isStoreFilterVisible" class="filter-options on-left">
                  <li
                    :class="{ 'active': sourceCompletePayoutsFilters.target_store.text === filteredStoreOptions[key].text }"
                    :key="filteredStoreOptions[key].value"
                    @click.stop="applyStoreFilter(value)"
                    v-for="(value, key) in filteredStoreOptions"
                    v-show="Object.keys(filteredStoreOptions).length > 0">
                      {{ filteredStoreOptions[key].text }}
                  </li>
                  <li class="not-found" key="store-not-found" v-if="Object.keys(filteredStoreOptions).length === 0">Store not found</li>
                </ul>
              </transition>
            </template>
          </SelectFilter>
        </li>

        <li class="filters-listing__filter" data-filter="calendar">
          <SelectFilter>
            <template #iconBefore>
              <CalendarIcon />
            </template>
            <template #label>
              <div class="label">{{ sourceCompletePayoutsFilters.date_range || "Select Range"  }}</div>
            </template>
            <template #iconAfter>
              <div class="icon-right absolute">
                <ChevronDown />
              </div>
            </template>
            <DateRangeFilter opens="right" :timeZone="timeZone" @onDateRangeSelected="onDateRangeSelectedHandler" :startDate="String(dateRange.startDate)" :endDate="String(dateRange.endDate)" />
          </SelectFilter>
        </li>

        <li class="right-action-container">
          <button class="btn-icon d-flex align-center clear-filters-btn" @click="onClearFiltersHandler">
            <CloseCircle /> <span class="btn-icon--text">Clear Filters</span>
          </button>
        </li>
      </ul>
    </section>

    <!-- Tables -->
    <section class="table payouts-table fixed-layout">
      <v-simple-table>
        <template>
          <thead>
            <tr>
              <th style="width: 3%;"></th>
              <th style="width: 12.5%;">Date (AEST)</th>
              <th style="width: 7.5%;">Payout ID</th>
              <th style="width: 25%;">Store Name</th>
              <th style="width: 9%">Amount</th>
              <th style="width: 12.5%;">Payment Status</th>
              <th style="width: 30%;" class="text-right">Actions</th>
            </tr>
          </thead>
          <tbody v-if="sourceCompletePayouts && sourceCompletePayouts.payouts.length === 0 && sourceCompletePayouts.payoutsToConfirm === 0 && totalSourcePayouts === 0">
            <tr class="no-hover">
              <td colspan="7" class="text-center empty-table-message">
                <p><strong>You have no payouts to review at this time.</strong></p>
                <p>Payouts will show when you mark a pushed order as fulfilled and <br/> when your destination store partner creates a payout.</p>
                <p class="mb-0 v2 pb-0">
                  <a href="https://help.syncio.co/en/articles/6402438-payouts-add-on-source-store-side" target="_blank" class="link link-underline">
                    Learn more about payouts
                    <IconNewTab />
                  </a>
                </p>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="payout in sourceCompletePayouts.payouts" :key="payout.payout_id">
              <td></td>
              <td>{{ payout.date }}</td>
              <td>{{ payout.payout_id }}</td>
              <td>{{ payout.store_name }}</td>
              <td class="tabular-fonts"><span v-if="payout.payout_total < 0">-$</span>{{ payout.payout_total | currencyFormatter(currency) }}</td>
              <td>
                <span v-if="payout.status === 'payment_confirmed'" class="status paid">Received</span>
                <span v-else class="status" :class="payout.status">{{ payout.status | removeUnderscore }}</span>
              </td>
              <td class="text-right">
                <v-btn elevation="0" class="btn btn-border" @click="viewPayoutHandler(payout, 'source')" :disabled="isViewPayoutRecordLoading">
                  <DocumentIcon/> <span class="ml-3">View Payout</span>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </section>

    <Pagination :pagination="sourceCompletePayouts.pagination" @updateCurrentPage="updateCurrentPageHandler" class="v2 bt-0" />

    <!-- View Payout Dialog -->
    <ViewPayoutDialog v-if="isViewPayoutDialogVisible" />
  </section>
</template>